import React, {useContext, useEffect, useState} from "react";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import {path, map} from 'ramda'
import {navigate} from "gatsby-link";

import {getFirebase} from "../../utils/signin";
import SelectSubscriptionExtraModal from "../../components/SelectSubscriptionExtraModal";
import {getCapabilitiesByExtras} from "../../client/dashboard/account";
import {getExtras, getExtrasLineItemsPrices, getPriceFromPlan, tvaId} from "../../pages/pricing";
import AppContext from "../../context/app";
import {getClientReferenceId} from "../../utils/rewardful";

function IndexPage() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [plan, setPlan] = useState(null);
    const [userHasAPlan, setUserHasAPlan] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
    const [isMonthlyPricing, setIsMonthlyPricing] = useState(true);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    const [isOpenSelectSubscriptionExtraModal, setIsOpenSelectSubscriptionExtraModal] = useState(false);
    const [canShowLaunchOfferMessage, setCanShowLaunchOfferMessage] = useState(false);
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [noStoreError, setNoStoreError] = useState(true);
    const {userLimits} = useContext(AppContext);
    console.log('userLimits::', userLimits);
    const extras = getExtras((isMonthlyPricing));
    const getCTALabel = (plan) => {
        const {offer: offerSubscribed} = userLimits || {};
        if (!offerSubscribed) return 'Souscrire';
        if (offerSubscribed === plan) return 'Ajouter des options';

        return 'Changer';
    }
    useEffect(() => {
        setTimeout(() => {
            // const obj = document.getElementById("price1");
            // animateDiscountPrice(
            //     obj,
            //     getPriceFromPlan('low', isMonthlyPricing).priceNotDiscounted,
            //     getPriceFromPlan('low', isMonthlyPricing).priceDiscounted,
            //     1000
            // );
            setCanShowLaunchOfferMessage(true);
        }, 2000)
        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
            setCurrentUserId(user ? user.uid : null);
            if (!user) return;

            const db = getFirebase().firestore();
            try {
                db.collection("customers")
                    .doc(user.uid)
                    .collection("subscriptions")
                    .where("status", "in", ["trialing", "active"])
                    .onSnapshot(async (snapshot) => {
                        if (!snapshot.empty) {
                            setUserHasAPlan(true);
                            return;
                        }
                    });
            } catch (e) {
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const perMonthText = isMonthlyPricing ? (
        <span className="text-base"> /mois</span>
    ) : (
        <span className="text-base"> /an</span>
    );
    const signInSuccessHandlerSubscribe = async (authResult, redirectUrl) => {
        if (isTrial) {
            navigate(`/app`);
            return;
        }

        console.log('authResult', authResult)
        setCurrentUserId(authResult.user.uid);
        const currentUserId = authResult.user.uid;
        handleOpenSelectSubscriptionExtraModal();
        // await handleRedirectCheckout(plan, currentUserId);
    }
    const handleOpenAuthModal = (isTrial = false) => {
        setIsTrial(isTrial);
        setIsOpenAuthModal(true);
    }
    const handleRedirectCheckout = async (plan, currentUserId, extras = []) => {
        setIsLoadingMutation(true);
        const db = getFirebase().firestore();
        const price = getPriceFromPlan(plan, isMonthlyPricing).priceId;
        const line_items = [
            {
                price,
                quantity: 1,
                tax_rates: [tvaId],
            },
            ...getExtrasLineItemsPrices(extras, isMonthlyPricing),
        ];
        console.log('line_items::', line_items, plan, price, isMonthlyPricing, getCapabilitiesByExtras(extras), getPriceFromPlan(plan, isMonthlyPricing))
        const docRef = await db
            .collection('customers')
            .doc(currentUserId)
            .collection('checkout_sessions')
            .add({
                line_items: [
                    {
                        price,
                        quantity: 1,
                        tax_rates: [tvaId],
                    },
                    ...getExtrasLineItemsPrices(extras, isMonthlyPricing),
                ],
                metadata: {
                    extras: JSON.stringify(getCapabilitiesByExtras(extras)),
                },
                allow_promotion_codes: true,
                success_url: `${window.location.origin}/app`,
                cancel_url: `${window.location.origin}/app/pricing`,
                clientReferenceId: getClientReferenceId(),
            });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
            const {error, url} = snap.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
                setIsLoadingMutation(false);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
                setIsLoadingMutation(false);
            }
        });
    }
    const handleHideNoStoreCreatedError = () => {
        setNoStoreError(false);
    }
    const handleOpenSelectSubscriptionExtraModal = (planId) => {
        // setSelectedPlanId(planId);
        setIsOpenSelectSubscriptionExtraModal(true);
    }
    const handleSubscribePlan = async (plan, extrasLineItems) => {
        setPlan(plan);
        if (!isSignedIn) {
            handleOpenAuthModal();
            return;
        }

        handleOpenSelectSubscriptionExtraModal();
        // userHasAPlan ?
        //     await handleOpenClientPortal() :
        // await handleRedirectCheckout(plan, currentUserId);
    }
    const handleSubscribePlanWithExtras = async (plan, extrasLineItems = []) => {
        setIsOpenSelectSubscriptionExtraModal(false);
        setPlan(plan);
        if (!isSignedIn) {
            handleOpenAuthModal();
            return;
        }

        await handleRedirectCheckout(plan, currentUserId, extrasLineItems);
    }
    const handleOpenDashboard = async () => {
        if (!isSignedIn) {
            handleOpenAuthModal(true);
            return;
        }

        navigate(`/app`);
    }
    const animateDiscountPrice = (obj, start, end, duration) => {
        if (!obj) return;

        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            obj.innerHTML = `${Math.floor(progress * (end - start) + start)}.99€`;
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    return (
        <div className=''>
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg className="animate-spin ml-1 mr-3 h-28 w-28 text-sapin-400"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            {/*{canShowLaunchOfferMessage && (*/}
            {/*    <div*/}
            {/*        className="flex flex-nowrap whitespace-nowrap overflow-hidden justify-end fixed animate-bounce-short z-50 left-0 right-0 w-full p-2 text-xl text-white bg-black border-b border-b-2 border-white text-center"*/}
            {/*        role="alert">*/}
            {/*        <div className='animate-slide-left flex flex-shrink-0 items-center text-center'>*/}
            {/*            <span className="font-medium">🔥 Offre de lancement ! </span> Profitez de <span className='text-white bg-red-600 p-1 text-sm rounded-lg mx-2'>-40%</span> de remises offert*/}
            {/*            sur les abonnements de nos premiers clients. 🚀*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            <section className="container mx-auto text-center mb-12">
                {/*{canShowLaunchOfferMessage && (<div className='pt-10 md:pt-4'/>)}*/}

                <div className="container mx-auto px-2 text-gray-800">

                    {/*<h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">Tarifs</h1>*/}
                    <div className='w-full text-center'>
                        <div onClick={() => {
                            setIsMonthlyPricing(!isMonthlyPricing)
                        }} className='flex items-center justify-center'>
                            <div
                                className='border border-gray-200 bg-white p-1 flex items-center justify-center rounded-lg'>
                                <div className={
                                    `${
                                        isMonthlyPricing ? 'bg-white' : 'bg-sapin-600'
                                    } p-3 rounded-lg cursor-pointer`
                                }>
                                <span className={
                                    `${
                                        isMonthlyPricing ? 'text-gray-600 text-sm hover:underline' : 'text-base text-white'
                                    } pl-2`
                                }>Annuellement</span>
                                    <span className='text-white bg-red-600 p-1 text-sm rounded-lg ml-2'>-20%</span>
                                </div>
                                <div className={
                                    `${
                                        isMonthlyPricing ? 'bg-sapin-600' : 'bg-white'
                                    } p-3 rounded-lg cursor-pointer`
                                }>
                                    <span className={
                                        `${
                                            isMonthlyPricing ? 'text-base text-white' : 'hover:underline text-gray-600 text-sm'
                                        } pl-2`
                                    }>Mensuellement</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex flex-col sm:flex-row justify-center md:my-12 mb-2 my-4 transform scale-100 md:scale-90">
                        <div
                            className="flex flex-col w-full sm:w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                            <div
                                className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                                <div className="pt-10 text-3xl font-bold text-center text-gray-800">Entrée</div>
                                <div className="w-full pb-8 text-center text-gray-400 text-sm border-b-4">(sans
                                    engagement)
                                </div>
                                <ul className="w-full text-center text-sm pt-12">
                                    <li className="border-b py-4">1 Menu</li>
                                    <li className="border-b py-4">15 lignes de menu</li>
                                    <li className="border-b py-4">Accès client aux QR codes illimité</li>
                                </ul>
                            </div>
                            <div
                                className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                {canShowLaunchOfferMessage ? (
                                    <div
                                        className="animate-fade-in relative w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                                    <span
                                        id='price1'>{getPriceFromPlan('low', isMonthlyPricing).price}</span>{perMonthText}
                                        <span
                                            className='absolute right-0 top-0 text-sm font-normal text-gray-500 line-through'> {getPriceFromPlan('low', isMonthlyPricing).priceNotDiscountedLabel}{perMonthText} </span>
                                    </div>
                                ) : (
                                    <div
                                        className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">{getPriceFromPlan('low', isMonthlyPricing).priceNotDiscountedLabel}{perMonthText}</div>
                                )}
                                <div className="flex items-center justify-center">
                                    <button onClick={() => handleSubscribePlan('low')}
                                            className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                                        {getCTALabel('starter')}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            className="flex flex-col w-full sm:w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
                            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow relative">
                                <div className="right-3 top-3 absolute">
                                    <span
                                        className="bg-blue-100 text-blue-800 text-xs font-semibold px-3.5 py-1.5 rounded dark:bg-blue-200 dark:text-blue-800">Offre la plus populaire</span>
                                </div>

                                <div className="w-full pt-12 text-3xl font-bold text-center text-gray-900">Plat</div>
                                <div className="w-full pb-8 text-center text-gray-400 text-sm">(sans
                                    engagement)
                                </div>
                                <div className="h-1 w-full gradient my-0 py-0 rounded-t"></div>
                                <ul className="pt-14 w-full text-center text-base font-bold">
                                    <li className="border-b py-4">Menus illimités</li>
                                    <li className="border-b py-4">Lignes de menu illimitées</li>
                                    <li className="border-b py-4">Accès client aux QR codes illimité</li>
                                    <li className="border-b py-4">Possibilité de prévenir les allergies</li>
                                </ul>
                            </div>
                            <div
                                className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                {canShowLaunchOfferMessage ? (
                                    <div
                                        className="animate-fade-in relative w-full pt-6 text-4xl font-bold text-center">
                                        <span
                                            id='price2'>{getPriceFromPlan('basic', isMonthlyPricing).price}</span>{perMonthText}
                                        <span
                                            className='absolute right-0 top-0 text-xl text-red-800 line-through font-normal'> {getPriceFromPlan('basic', isMonthlyPricing).priceNotDiscountedLabel}{perMonthText} </span>
                                    </div>
                                ) : (
                                    <div
                                        className="w-full pt-6 text-4xl font-bold text-center">{getPriceFromPlan('basic', isMonthlyPricing).priceNotDiscountedLabel}{perMonthText}</div>
                                )}
                                <div className="flex items-center justify-center">
                                    <button onClick={() => handleSubscribePlan('basic')}
                                            className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                                        {getCTALabel('dish')}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            className="flex flex-col w-full sm:w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                            <div
                                className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                                <div className="pt-10 text-3xl font-bold text-center text-gray-800">Dessert</div>
                                <div className="w-full pb-8 text-center text-gray-400 text-sm border-b-4">(sans
                                    engagement)
                                </div>
                                <ul className="w-full text-center text-sm">
                                    <li className="border-b py-4">Menus illimités</li>
                                    <li className="border-b py-4">Lignes de menu illimitées</li>
                                    <li className="border-b py-4">Accès client aux QR codes illimité</li>
                                    <li className="border-b py-4">Possibilité de prévenir les allergies</li>
                                    <li className="border-b py-4">Possibilité d'une photo par ligne de menu</li>
                                    <li className="border-b py-4">Carte de fidélité par QR code</li>
                                </ul>
                            </div>
                            <div
                                className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                {canShowLaunchOfferMessage ? (
                                    <div
                                        className="animate-fade-in relative w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                                        <span
                                            id='price3'>{getPriceFromPlan('pro', isMonthlyPricing).price}</span>{perMonthText}
                                        <span
                                            className='absolute right-0 top-0 text-sm text-gray-500 line-through font-normal'> {getPriceFromPlan('pro', isMonthlyPricing).priceNotDiscountedLabel}{perMonthText} </span>
                                    </div>
                                ) : (
                                    <div
                                        className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">{getPriceFromPlan('pro', isMonthlyPricing).priceNotDiscountedLabel}{perMonthText}</div>
                                )}
                                <div className="flex items-center justify-center">
                                    <button onClick={() => handleSubscribePlan('pro')}
                                            className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                                        {getCTALabel('dessert')}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {noStoreError && (
                <div
                    className="animate-bounce-short bg-black border text-white px-4 py-3 rounded fixed top-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert"
                >
                    {/*<strong className="font-bold">A! </strong>*/}
                    <div className="pr-10">
                        Veuillez séléctionner une des offres ci-dessous pour découvrir ou activer nos options (commandes
                        en ligne, carte de fidélité digitale).
                    </div>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3"
                          onClick={handleHideNoStoreCreatedError}>
                        <svg
                            className="fill-current h-6 w-6 text-white"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Fermer</title>
                            <path
                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                        </svg>
                    </span>
                </div>
            )}
            <SelectSubscriptionExtraModal
                isOpen={isOpenSelectSubscriptionExtraModal}
                setIsOpen={setIsOpenSelectSubscriptionExtraModal}
                selectedPlan={plan}
                extras={extras}
                perMonthText={perMonthText}
                handleSubscribePlanWithExtras={handleSubscribePlanWithExtras}
            />
        </div>
    );
}

export default IndexPage;
