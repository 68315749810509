import React, { forwardRef } from 'react';
import { isNil, pathOr } from 'ramda';
import classNames from 'classnames';

import ShowInputErrowMessage from './ShowErrorMessage';

const TextInput = forwardRef(
    (
        {
            name,
            containerClassName = '',
            inputClassName = '',
            label,
            description = null,
            errors = {},
            isTextArea = false,
            ...props
        },
        ref
    ) => {
        const error = pathOr(null, [name], errors);
        const hasError = !isNil(error);
        const inputClasses = classNames(`block w-full rounded-md shadow-sm sm:text-sm ${inputClassName}`, {
            'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500': !hasError,
            'border-red-300 focus:border-red-500 focus:ring-red-500': hasError
        });

        return (
            <>
                {label && (
                    <label htmlFor={label} className="block text-sm font-medium text-gray-700">
                        {label}
                    </label>
                )}
                <div className={`mt-1 ${containerClassName}`}>
                    {!isTextArea && (
                        <input
                            id={label}
                            name={name}
                            ref={ref}
                            type="text"
                            className={inputClasses}
                            {...props}
                        />
                    )}
                    {isTextArea && (
                        <textarea
                            id={label}
                            name={name}
                            ref={ref}
                            rows={3}
                            className={inputClasses}
                            {...props}
                        />
                    )}
                </div>
                {description && !hasError && <p className="mt-2 text-sm text-gray-500">{description}</p>}
                <ShowInputErrowMessage error={error} />
            </>
        );
    }
);

export default TextInput;
