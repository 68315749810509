import { useContext, useEffect, useState } from 'react';

import { DashboardContext } from '../components/DashboardLayout';

const defaultParams = {
    baseNumberOfStamps: 10,
    disclaimer: '',
    foregroundColor: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(0, 0, 0)',
    labelColor: 'rgb(102, 161, 130)',
};

const useDigitalCardParams = () => {
    const { store, storeDoc, createOrUpdateStore, isLoading, isLoadingMutation, getLoyaltyCard, stampAppleLoyaltyCard } =
        useContext(DashboardContext);
    const [displayParams, setDisplayParams] = useState(defaultParams);
    const [hasInit, setHasInit] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const initParams = async () => {
        await createOrUpdateStore({ loyaltyCardParams: displayParams });
        setHasInit(true);
    };

    useEffect(() => {
        if (!storeDoc || hasInit) return;
        if (storeDoc && !store.loyaltyCardParams) {
            initParams();
            return;
        }
        console.log('displayParams :: store.loyaltyCardParams', store.loyaltyCardParams)
        setDisplayParams(store.loyaltyCardParams);
        setTimeout(() => setHasInit(true), 200);
    }, [store, storeDoc]);

    useEffect(() => {
        if (!hasInit) return;
        clearTimeout(timeoutId);
        const id = setTimeout(async () => {
            await createOrUpdateStore({ loyaltyCardParams: displayParams });
        }, 2500);
        setTimeoutId(id);
        return () => clearTimeout(id);
    }, [displayParams]);

    return { displayParams, setDisplayParams, isLoading, isLoadingMutation, getLoyaltyCard, stampAppleLoyaltyCard };
};

export { useDigitalCardParams };
