// Import FirebaseAuth and getFirebase().
import React, {useContext, useState} from 'react';
import {find, reject} from 'ramda';
import {navigate} from 'gatsby';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

import CreateMenuItemsModal from '../../components/CreateMenuItemsModal';
import DeleteMenuItemModal from '../../components/DeleteMenuItemModal';
// import {skeletonProducts} from '../my-products';
import DeleteMenuModal from '../../components/DeleteMenuModal';
import EditMenuItems from '../../components/EditMenuItems';
import MenuItem from '../../components/Product';
import UpdateMenuModal from '../../components/UpdateMenuModal';
import AppContext from '../../context/app';
import {useMenu} from '../../hooks/menu';
import BePremiumModal from "../../components/BePremiumModal";
import ChangeVisibilityMenuItemModal from "../../components/ChangeVisibilityMenuItemModal";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((item, position) => ({
        ...item,
        position: position + 1
    }));
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    padding: grid,
    overflow: 'auto'
});

function MyMenu({id: menuId, location}) {
    const {isSignedIn} = useContext(AppContext);
    const [isOpenDeleteMenuModal, setIsOpenDeleteMenuModal] = useState(false);
    const [isOpenDeleteMenuItemModal, setIsOpenDeleteMenuItemModal] = useState(false);
    const [isOpenChangeVisibilityMenuItemModal, setIsOpenChangeVisibilityMenuItemModal] = useState(false);
    const [isOpenUpdateMenuModal, setIsOpenUpdateMenuModal] = useState(false);
    const [isOpenCreateMenuItemModal, setIsOpenCreateMenuItemModal] = useState(false);
    const [menuItemSelected, setMenuItemSelected] = useState(false);
    const [isOpenEditMenuItem, setIsOpenEditMenuItem] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPremiumError, setShowPremiumError] = useState(false);
    const [showImagePremiumError, setShowImagePremiumError] = useState(false);
    const {userLimits} = useContext(AppContext);
    const handleShowError = () => {
        setShowError(true);
        // setIsLoadingMutation(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    };
    const handleShowPremiumError = () => {
        setShowPremiumError(true);
        // setIsLoadingMutation(false);
        setTimeout(() => {
            setShowPremiumError(false);
        }, 50000);
    };
    const {
        myMenu,
        menuItems,
        setMenuItems,
        isLoading,
        isLoadingMutation,
        removeMenu,
        updateMenu,
        createMenuItem,
        updateMenuItem,
        removeMenuItem
    } = useMenu({
        menuId,
        handleShowError,
        handleShowPremiumError
    });
    const {id, title} = myMenu || {};
    const handleRemoveMenu = async (event) => {
        event.preventDefault();
        try {
            await removeMenu(menuId);
            setIsOpenDeleteMenuModal(false);
            navigate('/my-menus');
        } catch (e) {
            handleShowError();
        }
    };
    const handleRemoveMenuItem = async (event) => {
        event.preventDefault();
        if (!menuItemSelected) return;

        try {
            await removeMenuItem(menuItemSelected);
            setIsOpenDeleteMenuItemModal(false);
            setMenuItemSelected({});
        } catch (e) {
            handleShowError();
        }
    };
    const handleChangeVisibilityMenuItem = async (event) => {
        event.preventDefault();
        console.log('handleChangeVisibilityMenuItem', !menuItemSelected.isHidden, menuItemSelected)
        if (!menuItemSelected) return;

        try {
            await updateMenuItem({
                isHidden: !menuItemSelected.isHidden
            }, null, menuItemSelected.id);
            setIsOpenEditMenuItem(false);
            setIsOpenChangeVisibilityMenuItemModal(false);
        } catch (e) {
            handleShowError();
        }
    };
    const handleOpenCautionRemoveMenu = async (event) => {
        event.preventDefault();
        setIsOpenDeleteMenuModal(true);
    };
    const handleOpenCautionRemoveMenuItem = async () => {
        setIsOpenDeleteMenuItemModal(true);
    };
    const handleOpenCautionChangeVisibilityMenuItem = async () => {
        setIsOpenChangeVisibilityMenuItemModal(true);
    };
    const handleUpdateMenu = async (menu) => {
        try {
            await updateMenu(menu);
            setIsOpenUpdateMenuModal(false);
        } catch (e) {
            handleShowError();
        }
    };
    const handleUpdateMenuItem = async (menuItem, image, menuItemId = menuItemSelected?.id) => {
        try {
            await updateMenuItem(menuItem, image, menuItemId);
            setIsOpenEditMenuItem(false);
        } catch (e) {
            console.log('handleUpdateMenuItem err', menuItem, image, menuItemId, e);
            handleShowError();
        }
    };
    const handleCreateMenuItem = async (newMenuItem, image) => {
        try {
            if (!isSignedIn) throw 'error';
            if (menuItemSelected?.id) {
                await handleUpdateMenuItem(newMenuItem, image);
                return;
            }

            await createMenuItem(newMenuItem, image);
            setIsOpenEditMenuItem(false);
        } catch (error) {
            console.error('Error adding document: ', error);
            handleShowError();
        }
    };
    const handleOpenCreateMenuItemsModal = async (event) => {
        event.preventDefault();
        setMenuItemSelected({});
        setIsOpenEditMenuItem(true);
    };
    const handleOpenUpdateMenuModal = async (event) => {
        event.preventDefault();
        setIsOpenUpdateMenuModal(true);
    };
    const handleEditMenuItem = (menuItemId) => {
        setMenuItemSelected(find(({id}) => id === menuItemId, menuItems));
        setIsOpenEditMenuItem(true);
    };
    const handleOnDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(menuItems, result.source.index, result.destination.index);
        console.log('items', items);
        setMenuItems(items);
        const rejectUndefined = reject((attr) => !attr);
        const menuItemToUpdate = items.map(
            async (menuItem) => await handleUpdateMenuItem(rejectUndefined(menuItem), null, menuItem.id)
        );
        await Promise.all(menuItemToUpdate);
    };

    return (
        <div>
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg
                        className="animate-spin ml-1 mr-3 h-28 w-28 text-sapin-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
            )}
            <div className="pt-24 pb-24">
                <div className="px-3 mx-auto">
                    {isLoading ? (
                        <div className=" p-4 max-w-2xl w-full mx-auto">
                            <div className="animate-pulse flex space-x-40">
                                <div className="gradient h-96 w-80"></div>
                                <div className="flex-1 space-y-4 py-1">
                                    <div className="h-4 gradient rounded w-60"></div>
                                    <div className="space-y-2">
                                        <div className="h-5 gradient rounded"></div>
                                        <div className="h-5 gradient rounded w-5/6"></div>
                                    </div>
                                    <div className="h-4 gradient rounded w-60"></div>
                                    <div className="space-y-2">
                                        <div className="h-4 gradient rounded"></div>
                                        <div className="h-4 gradient rounded w-5/6"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full mx-auto">
                            <div className="flex justify-between">
                                <h3 className="text-gray-700 uppercase text-lg">{title}</h3>
                                <div>
                                    <button onClick={handleOpenUpdateMenuModal}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-blue-700"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                            />
                                        </svg>
                                    </button>
                                    <button onClick={handleOpenCautionRemoveMenu}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-red-400 ml-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <hr className="my-3"/>
                            <div className="mt-2">
                                <label className="text-blue-900 text-sm" htmlFor="count">
                                    Articles de menu :
                                </label>
                                <div className="mt-6 flex justify-center">
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-500 rounded"
                                        onClick={handleOpenCreateMenuItemsModal}
                                    >
                                        Ajouter un nouvel article au menu
                                    </button>
                                </div>
                                <div className="flex">
                                    <span className="mt-3 text-sm text-gray-500">
                                        {menuItems.length} Articles de menu
                                    </span>
                                </div>
                            </div>
                            <EditMenuItems
                                menuId={menuId}
                                menuItemSelected={menuItemSelected}
                                setIsOpen={setIsOpenEditMenuItem}
                                isOpen={isOpenEditMenuItem}
                                handleCreateMenuItem={handleCreateMenuItem}
                                handleOpenCautionRemoveMenuItem={handleOpenCautionRemoveMenuItem}
                                handleOpenCautionChangeVisibilityMenuItem={handleOpenCautionChangeVisibilityMenuItem}
                                handleShowImagePremiumError={setShowImagePremiumError}
                                canSetImage={!!userLimits?.images}
                            />
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            // style={getListStyle(snapshot.isDraggingOver)}
                                            {...provided.droppableProps}
                                        >
                                            <div
                                                className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 pb-6">
                                                {isLoading &&
                                                    new Array(5).map(() => (
                                                        <div className=" p-4 max-w-2xl w-full mx-auto">
                                                            <div className="animate-pulse flex space-x-40">
                                                                <div className="gradient h-72 w-full"></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {menuItems.map(
                                                    (
                                                        {title, price, image, id, description, quantity, isHidden},
                                                        i
                                                    ) => (
                                                        <Draggable key={id} draggableId={id} index={i}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    // style={getItemStyle(
                                                                    //     snapshot.isDragging,
                                                                    //     provided.draggableProps.style
                                                                    // )}
                                                                >
                                                                    <MenuItem
                                                                        handleClickProduct={
                                                                            handleEditMenuItem
                                                                        }
                                                                        title={title}
                                                                        price={price}
                                                                        image={image}
                                                                        id={id}
                                                                        description={description}
                                                                        quantity={quantity}
                                                                        isHidden={isHidden}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    )}
                </div>
            </div>
            <UpdateMenuModal
                menu={myMenu}
                handleUpdateMenu={handleUpdateMenu}
                isOpen={isOpenUpdateMenuModal}
                setIsOpen={setIsOpenUpdateMenuModal}
            />
            <DeleteMenuModal
                handleRemoveMenu={handleRemoveMenu}
                isOpen={isOpenDeleteMenuModal}
                setIsOpen={setIsOpenDeleteMenuModal}
            />
            <DeleteMenuItemModal
                handleRemoveMenuItem={handleRemoveMenuItem}
                isOpen={isOpenDeleteMenuItemModal}
                setIsOpen={setIsOpenDeleteMenuItemModal}
            />
            <ChangeVisibilityMenuItemModal
                handleRemoveMenuItem={handleChangeVisibilityMenuItem}
                isOpen={isOpenChangeVisibilityMenuItemModal}
                setIsOpen={setIsOpenChangeVisibilityMenuItemModal}
                isHidden={menuItemSelected?.isHidden}
            />
            <CreateMenuItemsModal
                handleCreateMenuItem={handleCreateMenuItem}
                isOpen={isOpenCreateMenuItemModal}
                setIsOpen={setIsOpenCreateMenuItemModal}
                menuId={id}
            />
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert"
                >
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">
                        Une erreur est survenue, veuillez réessayer plus tard.
                    </span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg
                            className="fill-current h-6 w-6 text-red-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path
                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                        </svg>
                    </span>
                </div>
            )}
            {showPremiumError && (
                <BePremiumModal isOpen={true} setIsOpen={() => {
                    navigate('/app/pricing')
                }}
                                text="Vous devez souscrire à une offre supérieure pour accéder à plus de capacité. Vous pourrez ainsi créer jusqu'à 5000 articles de menu."/>
            )}
            <BePremiumModal isOpen={showImagePremiumError} setIsOpen={() => {
                setShowImagePremiumError(false)
            }}
                            text={"Vous devez souscrire à l'offre \"Déssert\" pour pouvoir ajouter une image à vos articles de menu."}/>
        </div>
    );
}

export default MyMenu;
