import React, {Fragment, useContext, useState} from 'react';
import {Switch, Menu, Transition} from '@headlessui/react';
import {ArrowDownTrayIcon, BuildingStorefrontIcon, RectangleStackIcon} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import {saveAs} from 'file-saver';
import {CirclePicker} from 'react-color';
import {QRCode} from 'react-qrcode-logo';
import tinycolor from 'tinycolor2';
import { Link } from 'gatsby';

import {DashboardContext} from '../../../components/DashboardLayout';
import Loader from '../../../components/Loader';
import Modal from '../../../components/Modal';
import {useQrCodeParams} from '../../../hooks';
import QRCodesTables from './tables';
import {graphql} from "gatsby";

const DownloadMenu = ({items}) => (
    <div className="mx-auto">
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="inline-flex w-full justify-center rounded-md bg-sapin-500 px-4 py-2 text-sm font-medium text-white hover:bg-sapin-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    Télécharger
                    <ArrowDownTrayIcon
                        className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="z-10 -left-11 absolute mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        {items.map(({label, onClick, Icon}) => (
                            <Menu.Item>
                                {({active}) => (
                                    <button
                                        className={`${
                                            active ? 'bg-sapin-400 text-white' : 'text-gray-900'
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={onClick}
                                    >
                                        {Icon && <Icon className="mr-2 h-5 w-5"/>}
                                        {label}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    </div>
);

const ToggleOption = ({label, description, enabled, setEnabled}) => (
    <Switch.Group as="div" className="flex items-center justify-between py-4">
        <span className="flex flex-grow flex-col">
            <Switch.Label as="span" className="font-medium text-gray-900" passive>
                {label}
            </Switch.Label>
            <Switch.Description as="span" className="text-sm text-gray-500">
                {description}
            </Switch.Description>
        </span>
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
                enabled ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            )}
        >
            <span
                aria-hidden="true"
                className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
            />
        </Switch>
    </Switch.Group>
);

const ColorPickerOption = ({label, color: hexColor, setColor}) => {
    const [showPicker, setShowPicker] = useState(false);
    const color = tinycolor(hexColor);
    const colorName = !color.toName()
        ? hexColor
        : color.toName().charAt(0).toUpperCase() + color.toName().slice(1);

    return (
        <div className="flex items-center justify-between py-4">
            <span className="flex flex-grow flex-col">
                <span className="font-medium text-gray-900">{label}</span>
                <span className="text-sm text-gray-500">Peronnalisez votre qr code</span>
            </span>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center  relative">
                    <div
                        style={{backgroundColor: color}}
                        onClick={() => setShowPicker(!showPicker)}
                        className="cursor-pointer inline-flex items-center rounded-md border border-gray-200 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    >
                        <span className={color.isDark() ? 'text-white' : 'text-black'}>{colorName}</span>
                    </div>
                    {showPicker && (
                        <div
                            onMouseLeave={() => setShowPicker(false)}
                            className="z-30 bg-gray-100 border-2 border-gray-400 shadow sm:rounded-lg absolute top-14 right-0"
                        >
                            <div className="p-4">
                                <CirclePicker
                                    color={hexColor}
                                    colors={[
                                        '#f44336',
                                        '#e91e63',
                                        '#9c27b0',
                                        '#673ab7',
                                        '#3f51b5',
                                        '#2196f3',
                                        '#03a9f4',
                                        '#00bcd4',
                                        '#009688',
                                        '#4caf50',
                                        '#8bc34a',
                                        '#cddc39',
                                        '#ffeb3b',
                                        '#ffc107',
                                        '#ff9800',
                                        '#ff5722',
                                        '#795548',
                                        '#607d8b',
                                        '#ffffff',
                                        '#000000'
                                    ]}
                                    onChange={(color) => setColor(color.hex)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const QRCodes = ({ data, pageContext }) => {
    // const { blogCategories } = pageContext;
    console.log('blogCategories', data, pageContext)
    const {store} = useContext(DashboardContext);
    const {displayParams, qrCodeProps, setDisplayParams, isLoading, isLoadingMutation} = useQrCodeParams();
    const [tableModalIsOpen, setTableModalIsOpen] = useState(false);

    const saveQRCodeImage = () => {
        const qrCodeCanvas = document.getElementById('qrcode-store');
        const imageData = qrCodeCanvas.toDataURL('image/png');
        saveAs(imageData, 'qrcode.png');
    };

    const downloadMenuItems = [
        {
            label: 'QRCode restaurant',
            onClick: saveQRCodeImage,
            Icon: BuildingStorefrontIcon
        },
        {
            label: 'QRCode par tables',
            onClick: () => setTableModalIsOpen(true),
            Icon: RectangleStackIcon
        }
        // {
        //     label: 'QRCode réseaux',
        //     onClick: () => console.log('QRCode réseaux'),
        //     Icon: GlobeAltIcon
        // }
    ];

    return (
        <div className="container flex flex-col gap-10">
            <div
                className={classNames(
                    'h-qrcode w-qrcode p-4 mx-auto overflow-hidden rounded-lg bg-white shadow',
                    {'opacity-30': isLoading}
                )}
            >
                <QRCode
                    {...qrCodeProps}
                    value={store ? `${window.location.origin}/stores/${store?.slug}` : 'myStore'}
                />
            </div>
            <DownloadMenu items={downloadMenuItems}/>
            <div className="hidden">
                <QRCode
                    {...qrCodeProps}
                    value={store ? `${window.location.origin}/stores/${store?.slug}` : 'myStore'}
                    size={1000}
                    id="qrcode-store"
                />
            </div>
            <div className="bg-white shadow sm:rounded-lg relative">
                {isLoading && <Loader/>}
                <div className="px-4 py-5 sm:p-6 relative">
                    {isLoadingMutation && (
                        <div className="absolute top-6 right-3">
                            <Loader size="sm" disableAbsolute/>
                        </div>
                    )}
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Personnalisation</h3>
                    <div className="mt-5">
                        <ul role="list" className="divide-y divide-gray-200">
                            <li>
                                <ToggleOption
                                    label="Logo"
                                    description="Afficher ou pas le logo de votre restaurant"
                                    enabled={displayParams.displayLogo}
                                    setEnabled={() =>
                                        setDisplayParams((prev) => ({
                                            ...prev,
                                            displayLogo: !prev.displayLogo
                                        }))
                                    }
                                />
                            </li>
                            <li>
                                <ToggleOption
                                    label="Style QR"
                                    description="Afficher des points ou carrés sur le QRCode"
                                    enabled={displayParams.qrStyle == 'dots'}
                                    setEnabled={() =>
                                        setDisplayParams((prev) => ({
                                            ...prev,
                                            qrStyle: prev.qrStyle == 'dots' ? 'squares' : 'dots'
                                        }))
                                    }
                                />
                            </li>
                            <li>
                                <ColorPickerOption
                                    label="Couleur du QRCode"
                                    color={displayParams.fgColor}
                                    setColor={(newColor) =>
                                        setDisplayParams((prev) => ({...prev, fgColor: newColor}))
                                    }
                                />
                            </li>
                            <li>
                                <ColorPickerOption
                                    label="Couleur de fond"
                                    color={displayParams.bgColor}
                                    setColor={(newColor) =>
                                        setDisplayParams((prev) => ({...prev, bgColor: newColor}))
                                    }
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Modal isOpen={tableModalIsOpen} setIsOpen={setTableModalIsOpen}>
                <QRCodesTables qrCodeProps={qrCodeProps} closeModal={() => setTableModalIsOpen(false)}/>
            </Modal>
            <div className="bg-white shadow sm:rounded-lg mb-40">
                <div className="px-4 pt-5 sm:p-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Où placer ce QR code ? Comment créer les affiches et les imprimer ?</h3>
                    <div className="mt-3">
                        <p className="text-sm text-gray-900">
                            Découvrez nos articles dédiés à ce sujet en <Link to={'/qrcode'} className='text-sapin-600 hover:text-sapin-400 font-semibold'>cliquant sur ce lien</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QRCodes;
