import React, {useState} from 'react';

const ReferralProgramStats = (props) => {
    const {visitors = 0, leads = 0, conversions = 0} = props;
    const possibleDiscountsTotal = 3;
    const numberOfConversions = conversions > possibleDiscountsTotal ? possibleDiscountsTotal : conversions;
    const discount = conversions > 0 ? [25, 35, 50][numberOfConversions - 1] : 0;
    const remaining = possibleDiscountsTotal - conversions;

    return (
        <section className="mt-10 mb-8 p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-lg font-medium text-gray-800 mb-4">
                Statistiques d'affiliation
            </h3>
            <div className="flex items-center justify-between mb-2">
                <span className="text-gray-700 font-medium">Visiteurs</span>
                <span className="text-gray-900 font-bold">{visitors}</span>
            </div>
            <div className="flex items-center justify-between mb-2">
                <span className="text-gray-700 font-medium">Prospects</span>
                <span className="text-gray-900 font-bold">{leads}</span>
            </div>
            <div className="flex items-center justify-between mb-2">
                <span className="text-gray-700 font-medium">Conversions</span>
                <span className="text-gray-900 font-bold">{conversions}</span>
            </div>
            {discount > 0 && (
                <div className="flex items-center justify-between mb-2">
          <span className="text-green-700 font-medium">
            Réduction en cours
          </span>
                    <span className="text-green-900 font-bold">
            {discount}% sur votre prochain paiement
          </span>
                </div>
            )}
            <div className="flex items-center justify-between mb-2">
                {discount === 0 && (
                    <span className="text-gray-700 font-medium">
            Pas encore de réduction
          </span>
                )}
                {remaining === 3 && (
                    <span className="text-gray-900 font-bold">
              Vous avez atteint le nombre maximum de parrainages. Vous bénéficiez maintenant d'une réduction de 50% sur votre prochain paiement!
            </span>
                )}
                {remaining === 1 && (
                    <span className="text-gray-900 font-bold">
              Vous avez parrainé {conversions} amis! Il ne vous reste plus qu'un ami à parrainer pour bénéficier d'une réduction de 50% sur votre prochain paiement!
            </span>
                )}
                {remaining === 2 && (
                    <span className="text-gray-900 font-bold">
              Vous avez parrainé {conversions} ami! Parrainez encore {remaining} ami pour bénéficier d'une réduction de 50% sur votre prochain paiement!
            </span>
                )}
            </div>
        </section>
    );
}

export default ReferralProgramStats;
