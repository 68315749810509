import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";

import {navigate} from "gatsby-link";
import logoImg from "../images/logo-x5.png";

function Menu({ title, description, image, id }) {
  const [isExpanded, toggleExpansion] = useState(false);
  const handleRedirectToProject = () => {
      navigate(`/app/menus/${id}`);
  }

  return (
      <div onClick={handleRedirectToProject} className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden transform hover:scale-105 cursor-pointer">
          <div className="flex space-x-40 items-center justify-center">
              <img className="h-56  fill-current inline" src={logoImg}/>
          </div>
        <div className="px-5 py-3">
          <h3 className="text-gray-700 uppercase" style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical"
          }}>{title}</h3>
          <span className="text-gray-500 mt-2">{description}</span>
        </div>
      </div>
  );
}

Menu.propTypes = {
  title: PropTypes.string,
  budget: PropTypes.string
};

Menu.defaultProps = {
  title: '',
    budget: ''
};

export default Menu;
