import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useForm } from 'react-hook-form';

import { DashboardContext } from '../../components/DashboardLayout';
import Loader from '../../components/Loader';
import ShowInputErrowMessage from '../../components/ShowErrorMessage';
import TextInput from '../../components/TextInput';

const LogoImagePlaceholder = () => (
    <svg
        className="mx-auto h-8 w-8 text-gray-400"
        stroke="currentColor"
        fill="none"
        viewBox="0 -9 50 50"
        aria-hidden="true"
    >
        <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const CoverImagePlaceholder = () => (
    <svg
        className="mx-auto h-12 w-12 text-gray-400"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 48 48"
        aria-hidden="true"
    >
        <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Store = () => {
    const { t } = useTranslation('form');
    const [logoImage, setLogoImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [storeExist, setStoreExist] = useState(false);
    const { store, createOrUpdateStore, isLoading, isLoadingMutation } = useContext(DashboardContext);
    const { register, handleSubmit, watch, reset, formState } = useForm({ shouldUnregister: true });
    const { errors } = formState;
    const logo = watch('logo');
    const cover = watch('cover');
    const fileValidator = (files) => files.length > 0;
    const saveLocalImage = (file, setImage) => {
        if (!file) return;
        setImage(URL.createObjectURL(file));
    };

    useEffect(() => {
        if (!logo) return;
        saveLocalImage(logo[0], setLogoImage);
    }, [logo]);

    useEffect(() => {
        if (!cover) return;
        saveLocalImage(cover[0], setCoverImage);
    }, [cover]);

    useEffect(() => {
        if (!store) return;

        setLogoImage(store.logo?.image);
        setCoverImage(store.cover?.image);
        setStoreExist(true);
        reset(store);
    }, [store]);

    return (
        <div>
            {(isLoading || isLoadingMutation) && <Loader />}
            <form onSubmit={handleSubmit(createOrUpdateStore)}>
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                    <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Logo</label>
                            <div className="mt-1 flex items-center">
                                <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                                    {logoImage && <img src={logoImage} />}
                                    {!logoImage && <LogoImagePlaceholder />}
                                </span>
                                {logoImage && storeExist && (
                                    <input
                                        id="logo-upload"
                                        {...register('logo')}
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        className="sr-only"
                                    />
                                )}
                                {(!logoImage || !storeExist) && (
                                    <input
                                        id="logo-upload"
                                        {...register('logo', {
                                            required: 'Veuillez importer le logo de votre établissement',
                                            validate: fileValidator
                                        })}
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        className="sr-only"
                                    />
                                )}
                                <label
                                    htmlFor="logo-upload"
                                    className="ml-5 cursor-pointer rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Changer
                                </label>
                            </div>
                            <ShowInputErrowMessage error={errors.logo} />
                        </div>
                        <div className="sm:col-span-3">
                            <TextInput
                                {...register('name', {
                                    required: 'Veuillez renseigner le nom de votre établissement'
                                })}
                                label="Nom"
                                autoComplete="organization"
                                errors={errors}
                            />
                        </div>
                        <div className="sm:col-span-3">
                            <TextInput
                                {...register('phoneNumber', {
                                    required: t('phoneNumber.missing'),
                                    pattern: {
                                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/gim,
                                        message: t('phoneNumber.incorrect')
                                    }
                                })}
                                autoComplete="tel"
                                label="Numéro de téléphone"
                                errors={errors}
                            />
                        </div>
                        <div>
                            <TextInput
                                {...register('desc', {
                                    required: 'Brève description de votre établissement'
                                })}
                                isTextArea
                                label="À propos"
                                description="Brève description de votre établissement."
                                errors={errors}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Photo de couverture
                            </label>
                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                <div className="space-y-1 text-center">
                                    {coverImage && <img src={coverImage} />}
                                    {!coverImage && <CoverImagePlaceholder />}
                                    {coverImage && storeExist && (
                                        <input
                                            id="cover-upload"
                                            {...register('cover')}
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            className="sr-only"
                                        />
                                    )}
                                    {(!coverImage || !storeExist) && (
                                        <input
                                            id="cover-upload"
                                            {...register('cover', {
                                                required:
                                                    'Veuillez importer une photo de couverture pour votre établissement',
                                                validate: fileValidator
                                            })}
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            className="sr-only"
                                        />
                                    )}
                                    <label
                                        htmlFor="cover-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                        <span>Importer une photo</span>
                                    </label>
                                    <p className="text-xs text-gray-500">PNG, JPG up to 10MB</p>
                                </div>
                            </div>
                            <ShowInputErrowMessage error={errors.cover} />
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                            type="submit"
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-400 disabled:cursor-not-allowed focus:ring-offset-2"
                        >
                            Enregistrer
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Store;
