import React, {useContext, useState} from 'react';
import {navigate} from "gatsby-link";
import {addIndex, map, filter, slice} from 'ramda';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import moment from "moment";

import {isNilOrEmpty} from "../../utils";
import {getOrderStateLabel, useOrders} from "../../hooks/orders";
import OrderDetailsModal from "../../components/OrderDetailsModal";
import BePremiumModal from "../../components/BePremiumModal";
import AppContext from "../../context/app";

const mapIndexed = addIndex(map);

const skeletonMenus = [0, 0, 0, 0];

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((item, position) => ({
        ...item,
        position: position + 1
    }));
};

function Orders({location}) {
    const [isOpenOrderDetailsModal, setIsOpenOrderDetailsModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showError, setShowError] = useState(false);
    const [showPremiumError, setShowPremiumError] = useState(false);
    const {userLimits} = useContext(AppContext);
    const handleShowError = () => {
        setShowError(true);
        // setIsLoadingMutation(false);
        setIsOpenOrderDetailsModal(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    }
    const handleShowPremiumError = () => {
        setShowPremiumError(true);
        // setIsLoadingMutation(false);
        setIsOpenOrderDetailsModal(false);
        setTimeout(() => {
            setShowPremiumError(false);
        }, 50000);
    }
    const {
        isLoading,
        isLoadingMutation,
        updateOrderState,
        updateOrderOnlineState,
        isClosedOrdersOnline = false,
        orders = [],
    } = useOrders({
        handleShowError,
        handleShowPremiumError
    });
    const fetchOrdersByState = (state) => {
        switch (state) {
            case 'waiting':
                return filter(({state}) => state === 'waiting', orders);
            case 'InProgress':
                // return slice(1, 3, filter(({state}) => state === 'waiting', orders));
                return filter(({state}) => state === 'InProgress', orders);
            case 'toCollect':
                return filter(({state}) => state === 'toCollect', orders);
            case 'done':
                return filter(({state}) => state === 'done', orders);
            default:
                return [];
        }
    }
    const handleOpenOrdersOnline = async () => {
        await updateOrderOnlineState({isOpen: true});
    }
    const handleCloseOrdersOnline = async () => {
        await updateOrderOnlineState({isOpen: false});
    }
    const handleOpenOrderDetails = (order) => {
        setIsOpenOrderDetailsModal(true);
        setSelectedOrder(order);
    }
    const handleOnDragEnd = async result => {
        const {source, destination, draggableId} = result;
        if (!destination || source.droppableId === destination.droppableId) return;

        await updateOrderState(draggableId, destination.droppableId);
    };

    return (
        <div>
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg className="animate-spin ml-1 mr-3 h-28 w-28 text-sapin-400"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            <div>
                <main className="mb-8">
                    <div className="mx-auto">
                        <div className='flex md:flex-row items-center md:justify-between'>
                            <div>
                                <div className='flex flex-row items-center justify-center'>
                                    <h3 className="text-gray-700 text-2xl font-medium mr-2">Mes commandes du jour</h3>
                                    <div
                                        className={!isClosedOrdersOnline ? 'mt-1 w-3 h-3 bg-green-400 rounded-full' : 'mt-1 w-3 h-3 bg-red-900 rounded-full'}/>
                                </div>
                                <div className='flex'>
                                    <span className="mt-2 text-sm text-gray-500">{orders.length} commandes</span>
                                </div>
                            </div>
                            <div>
                                {!isClosedOrdersOnline ? (
                                    <div className='flex flex-col items-center justify-center'>
                                        <span className="text-green-400 text-xs">Les commandes sont ouvertes.</span>
                                        <div className='flex mt-1'>
                                            <button
                                                className="bg-black text-sm hover:bg-red-900 text-white font-bold py-2 px-4 rounded"
                                                onClick={handleCloseOrdersOnline}
                                            >
                                                Fermer les commandes
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='flex flex-col items-center justify-center'>
                                        <span className="text-red-600 text-xs">Les commandes sont fermées.</span>
                                        <div className='flex mt-1'>
                                            <button
                                                className="bg-green-400 text-sm text-white font-bold py-2 px-4 rounded"
                                                onClick={handleOpenOrdersOnline}
                                            >
                                                Ouvrir les commandes
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full mt-6">
                            {isLoading ? (
                                skeletonMenus.map(() => (
                                    <div className=" p-4 max-w-2xl w-full mx-auto">
                                        <div className="animate-pulse flex space-x-40">
                                            <div className="gradient h-72 w-full"></div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <div className="py-4 w-full mx-auto">
                                        <div className="grid gap-4 grid-cols-4 h-screen">
                                            {map((state) => (
                                                <Droppable droppableId={state}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            // style={getListStyle(snapshot.isDraggingOver)}
                                                            {...provided.droppableProps}
                                                        >
                                                            <div className='bg-gray-50 flex w-auto flex-col h-screen'>
                                                                <div
                                                                    className='flex justify-center items-center p-2 border-b-2 border-gray-400 py-3 mb-2'>
                                                                    <h3>{getOrderStateLabel(state)}</h3>
                                                                </div>
                                                                <div className='overflow-y-auto h-full'>
                                                                    {mapIndexed((order, i) => {
                                                                        console.log('order::', order)
                                                                        const {
                                                                            id,
                                                                            displayId,
                                                                            createdAt,
                                                                            state,
                                                                            total,
                                                                            items = []
                                                                        } = order;
                                                                        return (
                                                                            <Draggable key={id}
                                                                                       draggableId={id}
                                                                                       index={i}>
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        // style={getItemStyle(
                                                                                        //     snapshot.isDragging,
                                                                                        //     provided.draggableProps.style
                                                                                        // )}
                                                                                    >

                                                                                        <div
                                                                                            onClick={() => handleOpenOrderDetails(order)}
                                                                                            className="m-2 p-3 bg-white w-auto flex flex-col shadow">
                                                                                            <div
                                                                                                className="flex flex-row justify-between">
                                                                                                <h3 className="text-gray-700 uppercase font-semibold"
                                                                                                    style={{
                                                                                                        overflow: "hidden",
                                                                                                        textOverflow: "ellipsis",
                                                                                                        display: "-webkit-box",
                                                                                                        WebkitLineClamp: "2",
                                                                                                        WebkitBoxOrient: "vertical"
                                                                                                    }}>n° {displayId}</h3>
                                                                                                <span
                                                                                                    className="text-gray-600 text-sm">{total} €</span>
                                                                                            </div>
                                                                                            <div className='mb-1'
                                                                                                 style={{marginTop: -5}}>
                                                                                                <span
                                                                                                    className="text-gray-500 text-xs">Reçu à {moment(createdAt).format('LT')}</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                {map(({
                                                                                                          menuItem,
                                                                                                          note,
                                                                                                          quantity: orderQuantity
                                                                                                      }) => (
                                                                                                    <span
                                                                                                        key={`${menuItem.id}-note`}
                                                                                                        className="text-gray-700 text-sm">{orderQuantity}x {menuItem.title} • </span>
                                                                                                ), items)}
                                                                                            </div>
                                                                                            {!isNilOrEmpty(filter(({note}) => !!note, items)) && (
                                                                                                <div
                                                                                                    className='bg-yellow-200 px-2 py-1 mt-1'>
                                                                                    <span
                                                                                        className="text-yellow-700 text-sm">Cette commande contient des consignes spécifiques.</span>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        );
                                                                    }, fetchOrdersByState(state))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Droppable>
                                            ), ['waiting', 'InProgress', 'toCollect', 'done'])}
                                        </div>
                                    </div>
                                </DragDropContext>
                            )}

                        </div>
                    </div>
                    <OrderDetailsModal isOpen={isOpenOrderDetailsModal} setIsOpen={setIsOpenOrderDetailsModal}
                                       order={selectedOrder}/>
                    <BePremiumModal isOpen={(!userLimits?.onlineOrderEnabled)} setIsOpen={() => {navigate('/app/pricing')}}
                                       text={"Vous n'avez pas sousris à l'option \"Commandes en ligne\""}/>
                </main>
            </div>
            {showPremiumError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed top-40 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <div className='mr-10'>
                        <strong className="font-bold">Erreur, </strong>
                        <span className="block sm:inline">Vous devez souscrire à une offre supérieure pour accéder à plus de capacité.</span>
                    </div>
                    <div className='text-center'>
                        <button className="bg-blue-500 text-white rounded p-2 ml-4 mt-2 transform hover:scale-110"
                                onClick={() => navigate('/#pricing')}>Changer d'offre 🚀
                        </button>
                    </div>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             onClick={() => setShowPremiumError(false)}
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">Une erreur est survenue, veuillez réessayer plus tard.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
        </div>
    );
}

export default Orders;
