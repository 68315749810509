import React, {useContext, useState} from 'react';
import {head} from 'ramda';
import classNames from 'classnames';
import {SketchPicker} from 'react-color';
import {QRCode} from 'react-qrcode-logo';
import tinycolor from 'tinycolor2';

import {DashboardContext} from '../../components/DashboardLayout';
import Loader from '../../components/Loader';
import {useQrCodeParams, useTables} from '../../hooks';
import TextInput from "../../components/TextInput";
import {QrCodeIcon} from "@heroicons/react/24/outline";
import {useDigitalCardParams} from "../../hooks/digitalCardParams";
import BePremiumModal from "../../components/BePremiumModal";
import {navigate} from "gatsby-link";
import AppContext from "../../context/app";

const ColorPickerOption = ({label, color: hexColor, setColor}) => {
    const [showPicker, setShowPicker] = useState(false);
    const color = tinycolor(hexColor);
    const colorName = !color.toName()
        ? hexColor
        : color.toName().charAt(0).toUpperCase() + color.toName().slice(1);

    return (
        <div className="flex items-center justify-between py-4">
            <span className="flex flex-grow flex-col">
                <span className="font-medium text-gray-900">{label}</span>
                <span className="text-sm text-gray-500">Peronnalisez votre carte de fidélité</span>
            </span>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center  relative">
                    <div
                        style={{backgroundColor: color}}
                        onClick={() => setShowPicker(!showPicker)}
                        className="cursor-pointer inline-flex items-center rounded-md border border-gray-200 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    >
                        <span className={color.isDark() ? 'text-white' : 'text-black'}>{colorName}</span>
                    </div>
                    {showPicker && (
                        <div
                            onMouseLeave={() => setShowPicker(false)}
                            className="z-30 bg-gray-100 border-2 border-gray-400 shadow sm:rounded-lg absolute top-14 right-0"
                        >
                            <div className="p-4">
                                <SketchPicker
                                    color={hexColor}
                                    onChange={(color) => {
                                        console.log('StampsNumberOption', `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`)
                                        setColor(`rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const StampsNumberOption = ({label, numberOfStamps, setNumberOfStamps}) => {
    const getButtonClassName = (value) => {
        if (value !== numberOfStamps) return 'mr-1 border border-black text-black px-6 py-2 transform hover:scale-105 cursor-pointer rounded-md';

        return 'mr-1 gradient border border-black text-white px-6 py-2 transform hover:scale-105 cursor-pointer rounded-md';
    }

    return (
        <div className="flex items-center justify-between py-4">
            <span className="flex flex-grow flex-col">
                <span className="font-medium text-gray-900">{label}</span>
                <span className="text-sm text-gray-500">Peronnalisez votre carte de fidélité</span>
            </span>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                {[8, 10, 12].map(val => (
                    <div className={getButtonClassName(val)} onClick={() => setNumberOfStamps(val)}>
                        {val}
                    </div>
                ))}
            </div>
        </div>
    );
};


const TextOption = ({label, placeholder, handleChangeText, value = ''}) => {

    return (
        <div className="flex items-center justify-between py-4">
            <span className="flex flex-grow flex-col">
                <span className="font-medium text-gray-900">{label}</span>
                <span className="text-sm text-gray-500">Peronnalisez votre carte de fidélité</span>
            </span>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <TextInput placeholder={placeholder} containerClassName='w-80' onChange={handleChangeText}/>
            </div>
        </div>
    );
};

const DigitalCard = () => {
    const {store} = useContext(DashboardContext);
    const {userLimits} = useContext(AppContext);
    const {displayParams, setDisplayParams, isLoading, isLoadingMutation, getLoyaltyCard} = useDigitalCardParams();
    const handleGetLoyaltyCard = async () => {
        await getLoyaltyCard();
    }
    console.log('displayParams', displayParams)

    return (
        <div className="container flex flex-col gap-10">
            <div className="bg-white shadow sm:rounded-lg relative">
                {isLoading && <Loader/>}
                <div className="px-4 py-5 sm:p-6 relative">
                    {isLoadingMutation && (
                        <div className="absolute top-6 right-3">
                            <Loader size="sm" disableAbsolute/>
                        </div>
                    )}
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Personnalisation</h3>
                    <div className="mt-5">
                        <ul role="list" className="divide-y divide-gray-200">
                            <li>
                                <StampsNumberOption
                                    label="Nombre de tampons à valider avant récompense"
                                    numberOfStamps={displayParams?.baseNumberOfStamps}
                                    setNumberOfStamps={(val) =>
                                        setDisplayParams((prev) => ({...prev, baseNumberOfStamps: val}))
                                    }
                                />
                            </li>
                            <li>
                                <TextOption
                                    label="Déscription / Condition"
                                    placeholder='1 CASE - 1 COMMANDE MINIMUM DE 12€'
                                    value={displayParams?.disclaimer ?? ''}
                                    handleChangeText={(event) => {
                                        event.preventDefault();
                                        if (!event?.target?.value) return;

                                        const disclaimer = event?.target?.value;
                                        if (!disclaimer) return;

                                        console.log('event disclaimer',disclaimer);
                                        setDisplayParams((prev) => ({...prev, disclaimer}))
                                    }}
                                />
                            </li>
                            <li>
                                <ColorPickerOption
                                    label="Couleur du texte principal"
                                    color={displayParams.foregroundColor}
                                    setColor={(newColor) =>
                                        setDisplayParams((prev) => ({...prev, foregroundColor: newColor}))
                                    }
                                />
                            </li>
                            <li>
                                <ColorPickerOption
                                    label="Couleur du texte secondaire (des sous titres)"
                                    color={displayParams.labelColor}
                                    setColor={(newColor) =>
                                        setDisplayParams((prev) => ({...prev, labelColor: newColor}))
                                    }
                                />
                            </li>
                            <li>
                                <ColorPickerOption
                                    label="Couleur du fond de la carte"
                                    color={displayParams.backgroundColor}
                                    setColor={(newColor) =>
                                        setDisplayParams((prev) => ({...prev, backgroundColor: newColor}))
                                    }
                                />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text-center py-6">
                    <QrCodeIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true"/>
                    <p className="mt-2 text-sm text-gray-900">
                        Pour vous donner une idée du rendu de votre carte de fidélité,
                    </p>
                    <p className="text-sm text-gray-900">
                        vous pouvez la télécharger ici <strong>depuis votre smartphone</strong>.
                    </p>

                    <div className="flex sm:flex-row flex-col gap-6 justify-center mt-6">
                        <button
                            onClick={handleGetLoyaltyCard}
                            className="cursor-pointer inline-flex sm:mx-0 mx-auto items-center rounded-md border border-transparent gradient px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                            {/*<PrinterIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>*/}
                            Obtenir la carte de fidélité
                        </button>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow sm:rounded-lg mb-40">
                <div className="px-4 pt-5 sm:p-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Comment tamponner les cartes de fidélité
                        ?</h3>
                    <div className="mt-3">
                        <p className="text-sm text-gray-900">
                            Lorsque un client vous présente sa carte de fidélité digitale, il vous suffira de sortir
                            votre smartphone, de vous rendre sur votre application "Appareil Photo", et de scanner le QR
                            code se trouvant sur la carte de fidélité.
                        </p>
                        <p className="text-sm text-gray-900">
                            En scannant le QR code, vous serez rediriger sur votre Espace Pro eMenila afin de valider le
                            tampon via le bouton "Tamponner la carte".
                        </p>
                        <p className="text-sm text-gray-900">
                            La carte digitale du client se mettra à jour automatiquement.
                        </p>
                    </div>
                </div>
                <div className="px-4 pt-5 sm:p-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Comment mes clients peuvent obtenir la
                        carte de fidélité
                        ?</h3>
                    <div className="mt-3">
                        <p className="text-sm text-gray-900">
                            Vos clients peuvent obtenir votre carte de fidélité digitale directement depuis votre page
                            publique (accessible depuis votre QR code).
                            Sur votre page publique, on y retrouve vos menus mais aussi une section en bas de page leur
                            permettant d'obtenir une carte de fidélité, qui s'enregistrera soit dans leur application
                            Apple "Cartes" ou alors Android Wallet.
                        </p>
                    </div>
                </div>
            </div>
            <BePremiumModal isOpen={!userLimits?.loyaltyCardEnabled} setIsOpen={() => {navigate('/app/pricing')}}
                            text={"Vous n'avez pas sousris à l'option \"Carte de fidélité digitale\""}/>
        </div>
    );
};

export default DigitalCard;
