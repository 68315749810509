import React from 'react';
import { Router } from '@reach/router';
import { graphql } from 'gatsby';

import Account from '../../client/dashboard/account';
import DigitalCard from '../../client/dashboard/digital-card';
import Menu from '../../client/dashboard/menu';
import Menus from '../../client/dashboard/menus';
import Orders from '../../client/dashboard/orders';
import Pricing from '../../client/dashboard/pricing';
import QRCodes from '../../client/dashboard/qrcodes';
import StampLoyaltyCard from '../../client/dashboard/stamp-loyalty-card';
import Store from '../../client/dashboard/store';
import DashboardLayout from '../../components/DashboardLayout';
import ReferralProgram from "../../client/dashboard/referral-program";

const App = () => (
    <DashboardLayout>
        <Router basepath="/app">
            <Store path="/" />
            <Menus path="menus" />
            <Menu path="menus/:id" />
            <Orders path="orders" />
            <QRCodes path="qrcodes" />
            <DigitalCard path="digital-card" />
            <StampLoyaltyCard path="stamp-loyalty-card" />
            <Account path="account" />
            <ReferralProgram path="referral-program" />
            <Pricing path="pricing" />
        </Router>
        <Router basepath="/:lang/app">
            <Store path="/" />
            <Menus path="menus" />
            <Menu path="menus/:id" />
            <Orders path="orders" />
            <QRCodes path="qrcodes" />
            <DigitalCard path="digital-card" />
            <StampLoyaltyCard path="stamp-loyalty-card" />
            <ReferralProgram path="referral-program" />
            <Account path="account" />
            <Pricing path="pricing" />
        </Router>
    </DashboardLayout>
);

export default App;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
