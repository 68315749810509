import React from 'react'
import Modal from "../Modal";

const ChangeVisibilityMenuItemModal = ({handleRemoveMenuItem, isOpen = false, setIsOpen, isHidden}) => {

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div
                className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="relative  sm:max-w-xl sm:mx-auto">
                    <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                        <div className="max-w-md mx-auto">
                            <div className="flex items-center space-x-5">
                                <div
                                    className="h-14 w-14 bg-red-200 rounded-full flex flex-shrink-0 justify-center items-center text-red-500 text-2xl font-mono">!
                                </div>
                                {isHidden ? (
                                    <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                                        <h2 className="leading-relaxed">Êtes-vous sur de rendre visible cet article de menu ?</h2>
                                    </div>
                                ) : (
                                    <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                                        <h2 className="leading-relaxed">Êtes-vous sur de masquer cet article de menu ?</h2>
                                        <p className="text-sm text-gray-500 font-normal leading-relaxed">En masquant cet article de menu, il ne sera plus présent sur votre page/menu publique (accessible depuis vos QR code). À tout moment, vous pourrez le rendre visible depuis ce même bouton en revenant dessus. Cette fonctionnalité est bien pratique lorsque il vous manque des ingrédients ou lorsque vous faites des plats de saison.</p>
                                    </div>
                                )}
                            </div>
                            <div className="pt-6 flex items-center space-x-4">
                                <button
                                    onClick={() => setIsOpen(false)}
                                    type="reset"
                                    className="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none">
                                    <svg className="w-6 h-6 mr-3" fill="none" stroke="currentColor"
                                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                    Annuler
                                </button>
                                <button
                                    onClick={handleRemoveMenuItem}
                                    className="bg-black flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">
                                    {isHidden ? 'Rendre Visible' : 'Masquer'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ChangeVisibilityMenuItemModal;
