import React, {useContext, useEffect, useState} from 'react';

import {FaCheckCircle} from "react-icons/fa";
import ReferralProgramStats from "../../components/ReferralProgramStats";
import AppContext from "../../context/app";
import {useStore} from "../../hooks";

function ReferralProgram({location}) {
    const {user, isSignedIn, userLimits, isLoadingUser} = useContext(AppContext);
    const [showError, setShowError] = useState(false);
    const [showCopiedUrl, setShowCopiedUrl] = useState(false);
    const {affiliateLink} = userLimits;
    const {
        isLoading,
        generateAffiliateLink,
        affiliateStats
    } = useStore();
    const {visitors, leads, conversions} = affiliateStats ?? {};
    console.log('user, isSignedIn, userLimits, isLoadingUser', affiliateStats, user, isSignedIn, userLimits, isLoadingUser)
    const handleShowError = () => {
        setShowError(true);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    };
    const handleCopyUrl = () => {
        setShowCopiedUrl(true);
        navigator.clipboard.writeText(affiliateLink);
        setTimeout(() => {
            setShowCopiedUrl(false);
        }, 1000);
    }
    const handleGenerateAffiliateLink = async () => {
        try {
            await generateAffiliateLink();
        } catch (e) {
            handleShowError();
        }
    };
    const getLinkToShare = () => (
        <div className="mt-2">
            <div
                className="bg-sapin-500 text-white p-4 rounded-md flex items-center justify-between">
                <p className="text-lg font-medium mr-2">
                    Votre lien unique à partager:
                </p>
                <div className='flex items-center justify-between'>
                    <p className="text-lg font-bold truncate">
                        {affiliateLink}
                    </p>
                    <button onClick={handleCopyUrl}>
                        <svg className="h-10 w-10 text-sapin-50 hover:text-white  ml-1" fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"/>
                        </svg>
                    </button>
                </div>
            </div>
            {showCopiedUrl && (
                <div className="mt-2"><span
                    className="text-blue-700 bg-blue-50 p-2 rounded">URL copiée !</span>
                </div>
            )}
        </div>
    )

    return (
        <div>
            {isLoading && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg
                        className="animate-spin ml-1 mr-3 h-28 w-28 text-sapin-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
            )}
            <div className="pt-4 pb-24">
                <div className="container px-3 mx-auto">
                    {false ? (
                        <div className=" p-4 max-w-2xl w-full mx-auto">
                            <div className="animate-pulse flex space-x-40">
                                <div className="gradient h-32 w-full rounded"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full mx-auto">
                            {affiliateLink && (
                                <div className='mb-20'>
                                    <ReferralProgramStats visitors={visitors} leads={leads} conversions={conversions} />
                                    {getLinkToShare()}
                                </div>
                            )}
                            <section className="bg-white p-6 rounded-md shadow-md text-gray-800">
                                <h2 className="text-2xl font-bold mb-4">Parrainez vos amis et économisez jusqu'à 50% sur
                                    votre abonnement</h2>
                                <p className="text-lg mb-4">Aidez vos amis à augmenter leurs ventes et à fidéliser leurs
                                    clients grâce à notre solution de menu interactif avec QR code. Et économisez
                                    jusqu'à 50% sur votre abonnement en parrainant vos amis.</p>
                                <div className="py-6">
                                    <div className="flex items-center mb-4">
                                        <FaCheckCircle className="text-green-500 mr-2"/>
                                        <p className="text-gray-800">Invitez 1 ami et obtenez 25% de réduction</p>
                                    </div>
                                    <div className="flex items-center mb-4">
                                        <FaCheckCircle className="text-green-500 mr-2"/>
                                        <p className="text-gray-800">Invitez 2 amis et obtenez 35% de réduction</p>
                                    </div>
                                    <div className="flex items-center mb-4">
                                        <FaCheckCircle className="text-green-500 mr-2"/>
                                        <p className="text-gray-800">Invitez 3 amis ou plus et obtenez 50% de
                                            réduction</p>
                                    </div>
                                </div>
                                <div className="bg-sapin-50 rounded-md p-4 mb-4">
                                    <p className="text-lg font-bold mb-2">Comment ça marche ?</p>
                                    <ol className="list-decimal pl-4">
                                        <li className="mb-2">Partagez votre lien unique avec vos amis.</li>
                                        <li className="mb-2">Votre ami s'abonne à notre solution à partir de votre lien
                                            et obtient une réduction de 10% sur son abonnement.
                                        </li>
                                        <li className="mb-2">Lorsque votre ami a payé son premier mois d'abonnement,
                                            vous obtenez une réduction de 25% sur votre abonnement.
                                        </li>
                                        <li className="mb-2">Si vous parrainez 2 amis, vous obtenez une réduction de
                                            35%. Et si vous parrainez 3 amis, vous obtenez une réduction de 50% sur
                                            votre abonnement !
                                        </li>
                                    </ol>
                                </div>
                                {!affiliateLink ? (
                                    <button
                                        className="bg-green-500 hover:bg-green-600 text-white rounded-md py-2 px-4 text-lg font-bold"
                                        onClick={handleGenerateAffiliateLink}
                                    >
                                        Parrainez vos amis maintenant !
                                    </button>
                                ) : (
                                    getLinkToShare()
                                )}
                            </section>
                        </div>
                    )}
                </div>
            </div>
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert"
                >
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">
                        Une erreur est survenue, veuillez réessayer plus tard.
                    </span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg
                            className="fill-current h-6 w-6 text-red-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path
                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                        </svg>
                    </span>
                </div>
            )}
        </div>
    );
}

export default ReferralProgram;
