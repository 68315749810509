import React, { useContext } from 'react';
import { isEmpty, last } from 'ramda';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useForm } from 'react-hook-form';
import { QRCode } from 'react-qrcode-logo';

import { DashboardContext } from '../../../components/DashboardLayout';
import Loader from '../../../components/Loader';
import TextInput from '../../../components/TextInput';
import { useTables } from '../../../hooks';

const QRCodesTables = ({ qrCodeProps, closeModal }) => {
    const { store } = useContext(DashboardContext);
    const { register, handleSubmit } = useForm();
    const { tables, generateTables, isLoading } = useTables();

    const saveQRCodeImageTable = (id) => {
        const qrCodeCanvas = document.getElementById(`qrcode-store-table-${id}`);
        const imageData = qrCodeCanvas.toDataURL('image/png');
        saveAs(imageData, `qrcode-table-${id}.png`);
    };

    const saveAllQRCodeImageTable = async () => {
        const zip = new JSZip();
        for (const { id } of tables) {
            const qrCodeCanvas = document.getElementById(`qrcode-store-table-${id}`);
            const imageData = last(qrCodeCanvas.toDataURL('image/png').split(';base64,'));
            zip.file(`qrcode-table-${id}.png`, imageData, { base64: true });
        }
        const archive = await zip.generateAsync({ type: 'blob' });
        saveAs(archive, 'qrCodes.zip');
    };

    return (
        <div className="inline-block w-full px-4 py-10 max-w-4xl my-24 max-h-screen overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <div onClick={closeModal} className="cursor-pointer absolute md:left-5 md:top-5 top-2 left-2">
                <XCircleIcon className="h-7 w-7" />
            </div>
            {isLoading && <Loader size="lg" disableAbsolute />}
            {!isLoading && isEmpty(tables) && (
                <div className="flex flex-col gap-4 justify-center md:w-1/2 w-3/4 mx-auto">
                    <span>Combien de tables disposez-vous ? </span>
                    <form onSubmit={handleSubmit(generateTables)} className="flex flex-col gap-4">
                        <TextInput
                            {...register('count', {
                                required: 'Veuillez préciser le nombre de tables pour générer vos QRCodes'
                            })}
                            type="number"
                            min={1}
                        />
                        <button
                            type="submit"
                            className="bg-blue-500 flex justify-center items-center text-white p-3 rounded-md focus:outline-none"
                        >
                            Enregistrer
                        </button>
                    </form>
                </div>
            )}
            {!isLoading && !isEmpty(tables) && (
                <div className="flex flex-col gap-8">
                    <div className="flex flex-wrap justify-center gap-2 items-center">
                        <span className="sm:text-2xl text-xl font-bold">Sélectionnez vos qrCode</span>
                        <span
                            onClick={saveAllQRCodeImageTable}
                            className="cursor-pointer bg-sapin-400 text-white text-sm p-1 rounded-md focus:outline-none"
                        >
                            Tout sélectionner
                        </span>
                    </div>
                    <div className="grid md:grid-cols-6 sm:grid-cols-4 grid-cols-3 gap-4 justify-items-center">
                        {tables.map(({ uid, id }) => (
                            <div
                                onClick={() => saveQRCodeImageTable(id)}
                                className="cursor-pointer text-white text-2xl font-extrabold rounded-lg bg-sapin-500 hover:bg-sapin-400 flex justify-center items-center w-16"
                            >
                                {id}
                                <div className="hidden">
                                    <QRCode
                                        {...qrCodeProps}
                                        value={`${window.location.origin}/stores/${store?.slug}?table=${uid}`}
                                        size={1000}
                                        id={`qrcode-store-table-${id}`}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default QRCodesTables;
