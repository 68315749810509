import React, {useContext, useState} from 'react';
import {QrCodeIcon} from "@heroicons/react/24/outline";
import {useLocation} from "@reach/router";
import queryString from 'query-string';

import {DashboardContext} from '../../components/DashboardLayout';
import Loader from '../../components/Loader';

const StampLoyaltyCard = () => {
    const location = useLocation();
    const {customerId} = queryString.parse(location.search);
    const {isLoadingMutation, isLoading, stampAppleLoyaltyCard} = useContext(DashboardContext);
    const handleStampLoyaltyCard = async () => {
        await stampAppleLoyaltyCard(customerId);
    }

    return (
        <div className="container flex flex-col gradient h-3/4">
            <div className="gradient shadow sm:rounded-lg relative h-3/4">
                {isLoading && <Loader/>}
                <div className="px-4 py-5 sm:p-6 relative">
                    {isLoadingMutation && (
                        <div className="absolute top-6 right-3">
                            <Loader size="sm" disableAbsolute/>
                        </div>
                    )}
                    <h3 className="text-lg font-medium leading-6 text-white">Vous venez de scanner le QR code d'une carte de fidélité, souhaitez-vous la tamponer ?</h3>
                </div>
                <div className="text-center py-6">
                    <div className="flex flex-col gap-6 items-center justify-center mt-6">
                        <QrCodeIcon className="mx-auto h-20 w-20 text-white" aria-hidden="true"/>
                        <button
                            onClick={handleStampLoyaltyCard}
                            className="bg-white cursor-pointer w-40 sm:mx-0 mx-auto items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                            {/*<PrinterIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>*/}
                            Valider le tampon
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StampLoyaltyCard;
