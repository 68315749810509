import React, {useEffect, useState} from 'react'
import Modal from "../Modal";
import {getFirebase} from "../../utils/signin";
import {isNilOrEmpty} from "../../utils";

const CreateMenuItemsModal = ({handleCreateMenuItem, isOpen = false, setIsOpen, menuId}) => {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [userId, setUserId] = useState(null);
    // Listen to the Firebase Auth state and set the local state.
    const firebase = getFirebase();
    useEffect(() => {
        if (isNilOrEmpty(firebase)) return;

        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
            setUserId(user.uid);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const handleNewMenuItem = async (event) => {
        event.preventDefault();
        if (!userId || !isSignedIn) return 'SHOW ERROR';

        const newMenuItem = {
            authorId: userId,
            title: event.target.title.value,
            price: parseFloat(event.target.price.value),
            description: event.target.description.value,
            menusId: [menuId],
        }
        await handleCreateMenuItem(newMenuItem);
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div
                className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="relative  sm:max-w-xl sm:mx-auto">
                    <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                        <div className="max-w-md mx-auto">
                            <div className="flex items-center space-x-5">
                                <div
                                    className="h-14 w-14 bg-yellow-200 rounded-full flex flex-shrink-0 justify-center items-center text-yellow-500 text-2xl font-mono">i
                                </div>
                                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                                    <h2 className="leading-relaxed">Ajouter un article de menu</h2>
                                    <p className="text-xs text-gray-500 font-normal leading-relaxed">Gérez ici votre menu.</p>
                                </div>
                            </div>
                            <form className="divide-y divide-gray-200" onSubmit={handleNewMenuItem}>
                                <div
                                    className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <div className="flex flex-col">
                                        <label className="leading-loose">Titre de l'article de menu</label>
                                        <input type="text"
                                               name='title'
                                               className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                                               placeholder="Accras Morrues"/>
                                    </div>
                                    {/*<div className="flex flex-col">*/}
                                    {/*    <label className="leading-loose">Prix</label>*/}
                                    {/*    <input type="number"*/}
                                    {/*           name='price'*/}
                                    {/*           className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"*/}
                                    {/*           placeholder="5€"/>*/}
                                    {/*</div>*/}
                                    <div className="flex flex-col">
                                        <label htmlFor="price"
                                               className="mt-4 mb-1 uppercase text-grey-darker text-xs font-bold">Prix</label>
                                        <div className="flex flex-row">
                                            <input type="number" name="price"
                                                   className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"/>
                                            <span
                                                className="flex items-center bg-grey-lighter rounded rounded-r-none px-3 font-bold text-grey-darker">€</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="leading-loose">Description</label>
                                        <textarea
                                            name='description'
                                            placeholder="Tomate, persil,..."
                                            type="text"
                                            className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600 resize-none"
                                            rows="6"></textarea>

                                        {/*<input type="text"*/}
                                        {/*       name='description'*/}
                                        {/*       className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"*/}
                                        {/*       placeholder="Optionnel"/>*/}
                                    </div>
                                </div>
                                <div className="pt-4 flex items-center space-x-4">
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        type="reset"
                                        className="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none">
                                        <svg className="w-6 h-6 mr-3" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                        Annuler
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Ajouter
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CreateMenuItemsModal;
