import React, {useContext, useState} from 'react';
import * as queryString from "query-string";
import {navigate} from "gatsby-link";

import CreateMenuModal from "../../components/CreateMenuModal";
import Menu from "../../components/Menu";
import {useMenus} from "../../hooks/menus";
import AppContext from "../../context/app";
import BePremiumModal from "../../components/BePremiumModal";

const skeletonMenus = [0, 0, 0, 0];

function MyMenus({location}) {
    const [myMenusFiltered, setMyMenusFiltered] = useState(null);
    const [isOpenCreateMenuModal, setIsOpenCreateMenuModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPremiumError, setShowPremiumError] = useState(false);
    const {tag} = queryString.parse(location.search);
    const {userLimits} = useContext(AppContext);
    const handleShowError = () => {
        setShowError(true);
        // setIsLoadingMutation(false);
        setIsOpenCreateMenuModal(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    }
    const handleShowPremiumError = () => {
        setShowPremiumError(true);
        // setIsLoadingMutation(false);
        setIsOpenCreateMenuModal(false);
        setTimeout(() => {
            setShowPremiumError(false);
        }, 50000);
    }
    const {myMenus, isLoading, isLoadingMutation, handleCreateMenu} = useMenus({
        handleShowError,
        handleShowPremiumError
    });
    const myMenusToShow = myMenusFiltered ? myMenusFiltered : myMenus;

    const handleRemoveQuery = (queryToRemove) => {
        const queryToRemoveRegex = new RegExp(`[\?&]${queryToRemove}=[^&]+`, "i");
        history.replaceState && history.replaceState(
            null, '', location.pathname + location.search.replace(queryToRemoveRegex, '').replace(/^&/, '?')
        );
        window.location.reload();
    }
    const handleGetMyProductSearched = (event) => {
        const text = event.target.value;
        if (!text) {
            setMyMenusFiltered(null);
            return;
        }

        const regex = RegExp(text.toLowerCase(), 'g');
        setMyMenusFiltered(
            myMenus.filter(item =>
                item.title.toLowerCase().startsWith(text.toLowerCase()) ||
                item.title.toLowerCase().search(regex) >= 0 ||
                item.tags?.find(tag => tag.toLowerCase().startsWith(text.toLowerCase())),
            ),
        )
    };
    const handleCreateNewMenu = () => {
        setIsOpenCreateMenuModal(true);
    }

    return (
        <div className="container">
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg className="animate-spin ml-1 mr-3 h-28 w-28 text-sapin-400"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            <div className="pt-24">
                <main className="my-8">
                    <div className="container mx-auto px-6">
                        <div className="relative mt-6 max-w-lg mx-auto">
                                <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                                    <svg className="h-5 w-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    </svg>
                                </span>

                            <input
                                className="w-full border rounded-md pl-10 pr-4 py-2 focus:border-blue-500 focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="Rechercher"
                                onChange={handleGetMyProductSearched}
                            />
                        </div>
                        <h3 className="text-gray-700 text-2xl font-medium">Mes menus</h3>
                        <div className='flex'>
                            <span className="mt-3 text-sm text-gray-500">{myMenusToShow.length} Menus</span>
                            {tag && (
                                <button className="ml-1 mt-3 text-sm text-gray-500" onClick={() => {
                                    handleRemoveQuery('tag')
                                }}>
                                    <span className="text-sm text-gray-500">•</span>
                                    <span className="ml-1 text-sm text-gray-500 hover:underline">{tag}</span>
                                    <span className="ml-1 text-sm text-white rounded bg-gray-500 pl-1 pr-1">x</span>
                                </button>)}
                        </div>
                        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                            {isLoading ? (
                                skeletonMenus.map(() => (
                                    <div className=" p-4 max-w-2xl w-full mx-auto">
                                        <div className="animate-pulse flex space-x-40">
                                            <div className="gradient h-72 w-full"></div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className=" p-4 max-w-2xl w-full mx-auto">
                                    <div className="flex space-x-40">
                                        <button
                                            onClick={handleCreateNewMenu}
                                            className="rounded gradient h-72 w-full items-center justify-center flex flex-col transform hover:scale-105">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" fill="none"
                                                 viewBox="0 0 24 24" stroke="white">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                                                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                            </svg>
                                            <span
                                                className="text-white text-xl font-medium">Créer un nouveau menu</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                            {myMenusToShow.map(({title, description, image, id}) => (
                                <Menu key={id} title={title} description={description} image={image} id={id}/>
                            ))}

                        </div>
                    </div>
                    <CreateMenuModal handleCreateMenu={handleCreateMenu} isOpen={isOpenCreateMenuModal}
                                     setIsOpen={setIsOpenCreateMenuModal}/>
                </main>
            </div>
            {showPremiumError && (
                <BePremiumModal isOpen={true} setIsOpen={() => {navigate('/app/pricing')}}
                                text="Vous devez souscrire à une offre supérieure pour accéder à plus de capacité. Vous pourrez ainsi créer jusqu'à 5000 menus."/>
            )}
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">Une erreur est survenue, veuillez réessayer plus tard.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
        </div>
    );
}

export default MyMenus;
