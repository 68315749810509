import React, { useEffect, useRef, useState } from 'react';
import { isNil, reject } from 'ramda';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';

import { isNilOrEmpty } from '../../utils';
import { getFirebase } from '../../utils/signin';
import TextInput from '../TextInput';

const EditMenuItems = ({
    handleCreateMenuItem,
    handleOpenCautionRemoveMenuItem = Function.prototype,
    handleOpenCautionChangeVisibilityMenuItem = Function.prototype,
    menuId,
    menuItemSelected = {},
    setIsOpen,
    isOpen,
    canSetImage,
    handleShowImagePremiumError
}) => {
    const formRef = useRef();
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [userId, setUserId] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const { title, description, price, quantity, image, isHidden } = menuItemSelected;
    const { register, handleSubmit, clearErrors, formState, setValue } = useForm();
    const { errors } = formState;
    // Listen to the Firebase Auth state and set the local state.
    const firebase = getFirebase();
    useEffect(() => {
        if (isNilOrEmpty(firebase)) return;

        const unregisterAuthObserver = getFirebase()
            .auth()
            .onAuthStateChanged((user) => {
                setIsSignedIn(!!user);
                setUserId(user.uid);
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const resetFormValues = () => {
        setValue('title', title);
        setValue('description', description);
        setValue('price', price);
        setValue('quantity', quantity);
    };
    useEffect(() => {
        setImagePreview(null);
        clearErrors();
        resetFormValues();
        formRef.current?.reset();
    }, [menuItemSelected?.id]);
    const handleNewMenuItem = async (data) => {
        if (!userId || !isSignedIn) return 'SHOW ERROR';
        const { title, price, description, quantity } = data;

        const newMenuItem = reject(isNil, {
            authorId: userId,
            title,
            price: parseFloat(price),
            description,
            quantity,
            menusId: [menuId]
        });
        console.log('newMenuItem, imagePreview', newMenuItem, imagePreview);
        await handleCreateMenuItem(newMenuItem, imagePreview);
    };
    const handleRemoveMenuItem = (event) => {
        event.preventDefault();
        if (menuItemSelected?.id) handleOpenCautionRemoveMenuItem();

        setIsOpen(false);
    };
    const handleChangeVisibilityMenuItem = (event) => {
        event.preventDefault();
        if (menuItemSelected?.id) handleOpenCautionChangeVisibilityMenuItem();

        setIsOpen(false);
    };
    const handleShowImagePreview = (event) => {
        event.preventDefault();
        console.log('handleShowImagePreview', event);
        if (event.target.files.length <= 0) return;

        const file = event.target.files[0];
        const src = URL.createObjectURL(file);
        setImagePreview({
            src,
            file
        });
    };
    if (!isOpen) return null;

    return (
        <div className="flex flex-col relative my-6 w-full px-4 rounded-lg bg-gray-50">
            <div className="w-full pt-4">
                <div className="flex w-full justify-end items-end">
                    <div className="transform hover:scale-110 border border-gray-400 rounded bg-white p-1 pb-0 mr-3">
                        <button onClick={handleChangeVisibilityMenuItem}>
                            {isHidden ? (
                                <EyeIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                            ) : (
                                <EyeSlashIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                        </button>
                    </div>
                    <div className="transform hover:scale-110 border border-gray-400 rounded bg-white p-1 pb-0">
                        <button onClick={handleRemoveMenuItem}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-red-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit(handleNewMenuItem)} ref={formRef}>
                <div className="flex flex-col gap-8 md:flex-row py-10 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7">
                    <div className="flex flex-row flex-0 flex-wrap gap-4 md:w-1/2 w-full">
                        <div className="w-full">
                            <TextInput
                                {...register('title', {
                                    required: 'Veuillez renseigner le titre de votre article'
                                })}
                                errors={errors}
                                label="Titre"
                                defaultValue={title}
                                placeholder="Accras Morrues"
                            />
                        </div>
                        <div className="w-full">
                            <TextInput
                                {...register('description', {
                                    required: 'Veuillez renseigner la description de votre article'
                                })}
                                errors={errors}
                                label="Description"
                                defaultValue={description}
                                placeholder="Tomate, persil, piment, morue, ..."
                            />
                        </div>
                        <div className="sm:w-auto w-full">
                            <TextInput
                                {...register('quantity')}
                                errors={errors}
                                label="Quantité • Optionnel"
                                defaultValue={quantity}
                                placeholder="Les 6 ou 10gr"
                            />
                        </div>
                        <div className="flex flex-row justify-center sm:w-auto w-full">
                            <div className="flex-grow">
                                <TextInput
                                    label="Prix"
                                    {...register('price', {
                                        required: 'Veuillez renseigner le prix de votre article'
                                    })}
                                    errors={errors}
                                    type="number"
                                    step="0.01"
                                    min={0}
                                    defaultValue={price}
                                    placeholder="5€"
                                />
                            </div>
                            <span className="flex items-center bg-grey-lighter rounded text-2xl rounded-r-none pt-6 pl-2 font-bold text-grey-darker">
                                €
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 mr-4 md:w-1/2 w-full">
                        <label htmlFor="image" className="mb-1 uppercase text-grey-darker text-xs font-bold">
                            Image (jpg,png,jpeg)
                        </label>
                        <div className="cursor-pointer flex items-center justify-center w-full">
                            <div
                                className={
                                    image || imagePreview
                                        ? 'cursor-pointer flex flex-col w-full'
                                        : 'cursor-pointer flex flex-col w-full border-4 border-dashed hover:bg-gray-100 hover:border-gray-300'
                                }
                            >
                                {!canSetImage ? (
                                    <input
                                        name="image"
                                        className="opacity-0"
                                        accept="image/*"
                                        onClick={() => {
                                            handleShowImagePremiumError(true);
                                        }}
                                    />
                                ) : (
                                    <input
                                        {...register('image')}
                                        type="file"
                                        className="opacity-0"
                                        accept="image/*"
                                        onChange={handleShowImagePreview}
                                    />
                                )}
                                {image || imagePreview ? (
                                    <img
                                        className="w-full h-52 object-cover pb-6"
                                        src={imagePreview?.src ?? image}
                                        alt="your image"
                                    />
                                ) : (
                                    <div className="flex flex-col items-center justify-center pb-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-full h-12 text-gray-400 group-hover:text-gray-600"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                            Selectionner une photo de l'article
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-4 flex justify-center items-center space-x-4">
                    <button
                        onClick={() => setIsOpen(false)}
                        type="reset"
                        className="flex justify-center items-center w-40 text-gray-900 px-4 py-3 rounded-md focus:outline-none"
                    >
                        <svg
                            className="w-6 h-6 mr-3"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="bg-blue-500 flex justify-center items-center w-60 text-white px-4 py-3 rounded-md focus:outline-none"
                    >
                        {!menuItemSelected?.id ? 'Ajouter' : 'Sauvegarder'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditMenuItems;
