import React, {useContext, useEffect, useState} from "react";
import {map, last, pathOr, length} from "ramda";

import Modal from "../Modal";
import {getOrderStateLabel} from "../../hooks/orders";
import moment from "moment";

const OrderDetailsModal = ({
                               isOpen = false,
                               setIsOpen,
                               order,
                           }) => {
    if (!order) return null;

    const {displayId, state, note, createdAt, total, phoneNumber, items = []} = order;
    const handleGoBack = () => {
        setIsOpen(false);
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={handleGoBack}>
            <div
                className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="relative  sm:max-w-xl sm:mx-auto">
                    <div className={"ml-3 mt-3"}>
                        <button
                            type="reset"
                            onClick={handleGoBack}
                            className={`bg-white text-black h-10 w-10 rounded-full cursor-pointer outline-none`}>
                            <svg className="w-6 h-6 ml-2" fill="none" stroke="currentColor"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                    <div className='overflow-y-auto' style={{maxHeight: '42rem'}}>
                        <section className="pb-10">
                            <div className="px-4 pb-3 flex flex-col items-center justify-center">
                                <span className="text-gray-500 text-xl">n°</span>
                                <h1 className="text-gray-700 uppercase font-semibold text-5xl">{displayId}</h1>
                            </div>
                            <div className="px-4 pb-3 flex flex-col items-center justify-center">
                                <div className="px-4 py-2 bg-blue-200">
                                    <p className="text-blue-600 text-xs uppercase">{getOrderStateLabel(state)}</p>
                                </div>
                            </div>
                            <div className="py-2 w-full max-w-sm mx-auto rounded-md overflow-hidden">
                                <div>
                                    <span
                                        className="text-gray-500 text-sm">Reçu à {moment(createdAt).format('LT')}</span>
                                </div>
                                <div>
                                    <span className="text-black text-sm">Total: {total} €</span>
                                </div>
                            </div>
                            <hr/>
                            <div className="pt-4 w-full max-w-sm mx-auto rounded-md relative overflow-hidden">
                                <div className='pb-2'>
                                    <span className="text-gray-500 text-xs uppercase font-bold">Détails de la commande</span>
                                </div>
                                {map(({menuItem, note, quantity: orderQuantity}) => {
                                    console.log('phoneNumber', phoneNumber)
                                    const {image, title, price, quantity, description} = menuItem;

                                    return (
                                        <div
                                            className="w-full flex flex-row items-start justify-start max-w-sm mx-auto rounded-sm relative overflow-hidden"
                                        >
                                            <div className="pr-5 pb-3">
                                                <div className='flex flex-row items-center justify-center'>
                                                    <div className='w-1.5 h-1.5 bg-black mr-2 rounded-full'/>
                                                    <h3 className="text-gray-700 text-sm uppercase font-semibold"
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "2",
                                                            WebkitBoxOrient: "vertical"
                                                        }}>{title}</h3>
                                                    {orderQuantity && (
                                                        <div
                                                            className='ml-2 mb-2 bg-blue-700 rounded-full px-2 py-1 flex justify-center items-center'>
                                                                <span
                                                                    className="text-white text-xs leading-none">x{orderQuantity}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                {note && (
                                                    <div className='bg-black rounded-tr-xl rounded-b-xl p-2 ml-4'>
                                                        <p className="text-white text-xs font-bold">Consignes:</p>
                                                        <span className="text-white text-xs">{note}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }, items)}
                                <div className='flex items-center justify-center pt-4'>
                                    <span className="text-gray-500 text-xs">N° de téléphone du client: </span><span
                                    className="text-black text-sm ml-1"> {phoneNumber}</span>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default OrderDetailsModal;
